// Import our CSS
import styles from '../css/app.pcss'
import './function-enquiry'

// External Libraries
import 'promise-polyfill/src/polyfill';
import "babel-polyfill";
jQuery = window.jQuery = window.$ = require('jquery');
let Choices = require('choices.js');
import AOS from 'aos';
import Swiper from 'swiper/dist/js/swiper.js';
import MicroModal from 'micromodal';
import Vue from 'vue';
import BookingSearch from './components/BookingSearch.vue';
import Booking from './components/Booking.vue';
import Search from './components/Search.vue';
import { store } from './store';
import objectFitImages from 'object-fit-images';
import lazySizes from 'lazysizes'; 

import axios from 'axios';
import { stringify } from 'qs';

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

const axiosInstance = axios.create({
  headers: {
    'X-CSRF-Token': window.csrfTokenValue
  }
});

Vue.prototype.$axios = axiosInstance;

// Import JS
require('./map.js');
require('./collapsible');
require('./tabs');
require('./supplier-reveal');
require('./detect-element');
// Not needed anymore ? 
// require('./number-input');

// stop lazy sizes from default initialising
lazySizes.cfg.expFactor = 2;
lazySizes.cfg.init = false;

lazySizes.init(); 

// App main
const main = async () => {

  lazySizes.init();
  
  window.onload = new function() {
    // stop the menu from sliding across the page on load
    $('.menu').each(function() {
      $(this).removeClass('hidden')
    });

    // Splash page
    setTimeout(() => {
      $('.page-content').css('display', 'block');
      $('.splash-page').addClass('hide');

      // Init element animation -> slow fade up and in
      AOS.init({
        offset: 0,
        duration: 2000,
        once: true,
      });

      // Modal    
      if (location.pathname === '/events') {
        MicroModal.init();
        $('a[href="/sign-up"]').on('click', function (e) {
          e.preventDefault();
          MicroModal.show('modal-mailchimp');
        });
      }

      // swiper for more rooms
      var roomSwiper = new Swiper('.swiper-room-container', {
        loop: true,
        spaceBetween: 40,
        centeredSlides: false,
        slidesPerView: 2,
        autoHeight: true,
        
        breakpoints: {
          
          // Medium Break Point
          767: {
            spaceBetween: 15,
            slidesPerView: 1,
            
          },
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })

      // swiper room header gallery
      var roomSwiper = new Swiper('.swiper-page-header-container', {
        loop: true,
        spaceBetween: 0,
        centeredSlides: false,
        slidesPerView: 1,
        autoHeight: true,

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })

      // If url already has # scroll here
      if (location.hash !== '') {
        if ($(location.hash).length) {
          $('html, body').stop().animate({
            scrollTop: $(location.hash).offset().top - 100
          }, 400);
        }
      }

      // clicking on header scrolls user down as long as booking form not present
      if ($('header').not('.is-slider').length) {
        if (!$('#booking-form').length) {
          $('header').css('cursor', 's-resize'); 
          $('header').on('click', function () {
            $('html, body').stop().animate({
              scrollTop: $('header').height()
            }, 400);
          });
        }
      }

    }, 1000);

    setTimeout(() => {
      $('.splash-page').addClass('hidden');
    }, 2000);
    
    // home page slide out 
    if ($('#slide-out').length) {
      $('.book-now').on('click', function() {
        $('#slide-out').addClass('slide-in').removeClass('slide-out');
        $('.dimmer').addClass('show');

        $('.dimmer').on('click', function () {
          $('#slide-out').removeClass('slide-in').addClass('slide-out');
          $('.dimmer').removeClass('show');
        });
      });
    }
  }

  // Room bookings
  if ($('#booking').length) {
    const booking = new Vue({
      el: '#booking',
      store,

      components: {
        BookingSearch,
        Booking,
        Search,
      }
    });
  }

  if ($('#booking-form').length) {
    const booking = new Vue({
      el: '#booking-form',
      store,

      components: {
        Search,
      }
    });
  }

  // reveal the invite friends form
  $('#invite-friends').click(function(e) {
    e.preventDefault();
    $('#form-hidden').removeClass('show').addClass('hide');
    $('#form-shown').removeClass('hide hidden').addClass('show');
  });

  // choices init
  if ($('select.border-b').length) {
    const choices = new Choices('select.border-b', {
      itemSelectText: '',
      searchEnabled: false,
      classNames: {
        containerOuter: 'choices bordered-bottom'
      }
    });
  }

  if ($('select.border').length) {
    const choices = new Choices('select.border', {
      itemSelectText: '',
      searchEnabled: false,
      classNames : {
        containerOuter: 'choices bordered'
      }
    });
  }

  if ($('select.small').length) {
    const choices = new Choices('select.small', {
      itemSelectText: '',
      searchEnabled: false,
      classNames: {
        containerOuter: 'choices small'
      }
    });
  }

  // Hash links 
  // Check to see if section exists on current page
  $("a[href*='#']").on('click', function (e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let hash = href.substr(href.indexOf('#'), href.length);

      if ($(hash).length) {
        $('html, body').stop().animate({
          scrollTop: $(hash).offset().top - 100
        }, 400);

        $('#menuToggle input[type="checkbox"]').prop("checked", false);
      } else {
        window.location = href;
      }
  });

  $(".tab-selector").on('click', function (e) {
    AOS.refresh();
  });

  // Guest input
  $('.tooltip').on('click', function(e) {
    $(this).toggleClass('open');
  });

  $('.tooltip .tooltiptext').on('click', function(e) {
    e.stopPropagation();
  });

  // Multi-select
  $('.tooltip.multi-select input').on('change', function(e) {
    $(this).closest('.multi-select').find('.total').html($(this).closest('.multi-select').find('input:checked').length);
  });

  // form fields
  $('input').blur(function() {
    if (!$(this).val()) {
      $(this).removeClass('is-active');
    }
  });

  $('input').focus(function () {
    $(this).addClass('is-active');
  });

  // Invalid form field scroll to view
  var elements = document.querySelectorAll('input,select,textarea');
  var invalidListener = function () {
    this.scrollIntoView(false);
  };

  for (var i = elements.length; i--;) {
    elements[i].addEventListener('invalid', invalidListener);
  }

  // Menu
  $('#menuToggle input').click(function() {
    $('body').toggleClass('overflow-y-hidden');
  });

  // If header is in view hide navigation
  window.addEventListener('scroll', function (e) {
    if (isOnScreen($('header'))) {
      $('nav#fixed-nav').removeClass('show').addClass('hide');
    } else {
      $('nav#fixed-nav').removeClass('hide').addClass('show');
    }
  });

  // IE fix for object fit
  objectFitImages();
  
}

// Execute async function
main().then(vm => {})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept()
}
