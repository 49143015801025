import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        filters: {
            adults: 1,
            children: 0,
            animals: 0,
            infants: 0,
            feature: [],
            start_date: moment().add(1, 'days'),
            end_date: moment().add(3, 'days'),
            read: true
        },
        cart: {
            fee: 2.5,
            items: [],
            addons: [],
            discount_code: '',
            discount: null,
            eta: '',
            reason: 4,
            notes: '',
            card_number: '',
            card_name: '',
            card_cvc: '',
            card_expiry: '',
            card_type: []
        },
    },
    mutations: {
        adults(state, value) {
            state.filters.adults = value;
        },
        setFilter(state, value) {
            Object.keys(value).forEach(key => {
                if (state.filters.hasOwnProperty(key)) {
                    state.filters[key] = value[key];
                }
            });
        },
        addToCart(state, value) {
            state.cart.items[0] = value;
        },
        addInventoryToCart(state, value) {
            state.cart.addons.push(value);
        },
        addInventoryListToCart(state, value) {
            state.cart.addons = value;
        },
        removeFromCart(state, index) {
            state.cart.splice(index, 1);
        }
    },
    getters: {
        filters(state) {
            return state.filters;
        },
        cart(state) {
            return state.cart;
        }
    }
});