<template>
    <section id="book">
        <component :is="componentName" v-on:show-component="showComponent($event)" :filters="filters">
            <span slot="noResults"><slot name="noResults"></slot></span>
        </component>
    </section>
</template>

<script>
// Import 
import axios from 'axios';
import BookingSearch from './BookingSearch.vue';
import BookingCheckout from './BookingCheckout.vue';
import Common from './mixins/common';

export default {
    name: 'booking',

    props: ['filters'],

    components: {
        BookingSearch,
        BookingCheckout
    },

    mixins: [Common],

    data() {
        return {
            componentName: 'BookingSearch',
            checkoutShown: false,
            bookingDetails: true,
            addons: false,
            payment: false,
        };
    },

    methods: {
        showComponent(name) {
            this.componentName = name;
            this.scrollTop();
        },
    }
}
</script>