<template>
    <div v-if="tariffsAvailable.length > 0">
        <div class="flex flex-wrap p-20 border border-b-0 border-red">
            <div class="w-full flex flex-col lg:hidden pl-0 lg:pl-20">
                <div class="flex flex-wrap justify-between pb-30">
                    <h2 class="secondary-heading w-full xl:w-9/12 text-red">{{ room.category_name }}</h2>
                    <div class="my-auto w-4/12 text-left xl:text-right pt-10">
                        <h4 class="sub-heading my-auto text-red">NZD ${{ getLowestPricePerNigth }}</h4>
                        <p class="body-copy uppercase text-red">Per Night</p>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-4/12 xl:w-5/12 pr-0 lg:pr-20">
                <!-- swiper -->
                <div class="swiper-room-gallery-container w-full overflow-x-hidden overflow-y-hidden relative" ref="gallery">
                    <div class="swiper-wrapper">

                        <div class="swiper-slide flex" v-for="image in category.images" :key="image.id">
                            <div class="w-full relative pb-3/4">
                                <img :src="cropImage(image.image_url)" :alt="image.image_type_name" class="w-full absolute object-cover h-full top-0 left-0">
                            </div>
                        </div>
                    
                    </div>
                    <div class="swiper-button-prev focus:outline-none hover:opacity-50" style="width: 12px; height: 20px; background-size: 12px 20px;"></div>
                    <div class="swiper-button-next focus:outline-none hover:opacity-50" style="width: 12px; height: 20px; background-size: 12px 20px;"></div>
                </div>
            </div>
            <div class="w-full lg:w-8/12 xl:w-7/12 flex flex-col pl-0 lg:pl-20">
                <div class="hidden lg:flex flex-wrap justify-between pb-45">
                    <h2 class="secondary-heading w-full xl:w-8/12 text-red">{{ room.category_name }}</h2>
                    <div class="w-3/12 text-left xl:text-right">
                        <h4 class="sub-heading my-auto capitalize text-red">From ${{ getLowestPricePerNigth }} / Night</h4>
                    </div>
                </div>
                <div class="flex flex-wrap pb-15 pt-20 lg:pt-0">
                    <div class="w-full md:w-8/12 lg:w-10/12 mt-auto">
                        <p class="body-copy text-red"><pre class="body-copy text-red whitespace-pre-line">{{ category.category_description | stripHtml }}</pre></p>
                    </div>
                </div>
                <!-- <div class="flex flex-wrap pb-25">
                    <div class="w-full md:w-4/12 lg:w-2/12">
                        <h4 class="sub-heading text-red">People</h4>
                    </div>
                    <div class="w-full md:w-8/12 lg:w-10/12 mt-auto">
                        <p class="body-copy text-red">{{ category.category_max_adults }}x Adults / {{ category.category_max_children }}x Children + {{ category.category_max_infants }}x Infants</p>
                    </div>
                </div> -->
                <!-- <div class="flex flex-row">
                    <div class="w-2/12 hidden lg:block">

                    </div>
                    <div class="w-10/12" v-if="category.features.length > 0">
                        <p class="body-copy text-red">{{ roomFeatures }}</p>
                    </div>
                </div> -->
                <div class="flex flex-row mt-auto">
                    <div class="w-2/12 hidden lg:block">

                    </div>
                    <div class="w-full lg:w-10/12 flex flex-wrap justify-between relative mt-20 md:mt-0">
                        <div class="mt-auto">
                            
                        </div>

                        <div class="absolute bottom-0 right-0 w-full md:w-5/12 lg:w-6/12 xl:w-5/12 z-20">
                            <button class="btn btn-red pt-8 pb-8 mt-20 md:mt-0 w-full text-center" :class="{ 'loading': loadingAvailability }" @click="openCalendar()">Show Availabilty</button>
                        </div>
                        <date-range-picker
                            class="availability" 
                            ref="picker"
                            :opens="'left'"
                            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                            :singleDatePicker=false
                            :timePicker=false
                            v-model="dateRange"
                            :showWeekNumbers=false
                            :showDropdowns=false
                            :autoApply=true
                            :ranges=false
                            :dateFormat="isInvalidDate"
                            :linkedCalendars=false
                            @toggle="toogleAvailability"
                            >

                            <div slot="input" slot-scope="picker" style="width: 100%;" class="text-off-white">
                                Show Availability
                            </div>
                
                        </date-range-picker>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(tariff, index) in tariffsAvailable" :key="index" class="flex flex-wrap items-center px-20 py-10 border border-red bg-white" :class="{'mb-30': index == tariffsAvailable.length - 1, 'border-b-0': index != tariffsAvailable.length - 1}">
            <div class="w-6/12 md:w-3/12 lg:w-4/12">
                <h4 class="sub-heading text-red pb-10 md:pb-0">{{ tariff.tariff_label }}</h4>
            </div>

            <div class="w-6/12 md:w-3/12 lg:w-4/12">
                <p class="body-copy my-auto text-red pb-10 md:pb-0">{{ tariff.tariff_message }}</p>
            </div>

            <div class="w-6/12 md:w-3/12 lg:w-2/12">
                <p class="body-copy my-auto text-red">${{ getPricePerNigth(tariff) }} / Night</p>
            </div>

            <div class="w-6/12 md:w-3/12 lg:w-2/12 text-right">
                <a href="#details" class="btn btn-red pt-13 pb-12 max-w-full md:max-w-170 px-45 whitespace-no-wrap block ml-auto" @click="bookNow(tariff)">Book Now</a>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Swiper from 'swiper/dist/js/swiper.js';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment'
import Common from './mixins/common';

export default {
    name: 'booking-room',
    props: ['room', 'category'],

    components: {
        DateRangePicker
    },

    mixins: [Common],

    data() {
        return {
            dateRange: {
                startDate: this.$store.getters.filters.start_date,
                endDate: this.$store.getters.filters.end_date
            },

            loadingAvailability: false,
            availability: []
        }
    },

    mounted() {
        var swiper = new Swiper('.swiper-room-gallery-container', {
            loop: true,
            spaceBetween: 0,
            centeredSlides: false,
            slidesPerView: 1,
            autoHeight: true,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    },

    computed: {
        roomFeatures() {
            return _.map(this.category.features, 'feature_name').join(', ');
        },

        tariffsAvailable() {
            return _.filter(this.room.tariffs_available, o => o.tariff_success == 'true');
        },

        getLowestPricePerNigth() {
            let flat = _.filter(this.room.tariffs_available, o => o.tariff_success == 'true');

            let tariffs = _.flatMap(flat, o => {
                return o.tariffs_quoted;
            });

            let minPrice = 1000000000000;

            tariffs.forEach((tariff, index) => {
                Object.keys(tariff).forEach((key) => {
                    if (tariff[key].calculated_amount < minPrice) {
                        minPrice = tariff[key].calculated_amount;
                    }
                });
            });

            return minPrice;
        },
    },

    methods: {
        bookNow(tariff) {
            let item = this.room;
            item.category = this.category;
            item.period_from = this.$store.getters.filters.start_date;
            item.period_to = this.$store.getters.filters.end_date;
            item.adults = this.$store.getters.filters.adults;
            item.children = this.$store.getters.filters.children;
            item.infants = this.$store.getters.filters.infants;
            item.tariff = tariff;
            item.guest = {
                'guest_firstname': '',
                'guest_lastname': '',
                'address_street': '',
                'address_city': '',
                'address_postcode': '',
                'address_state_name': '',
                'address_country_name': '',
                'guest_email': '',
                'guest_phone': ''
            };

            this.$store.commit('addToCart', item);
            this.$emit('show-component', 'BookingCheckout');
        },

        isInvalidDate(classes, date) {
            let today = moment();
            let after = moment().add(12, 'months');
            
            if (!classes.disabled) {
                classes.disabled = date.isBefore(today, 'day') || date.isAfter(after, 'month');
            }

            if (!classes.disabled) {
                let result = false;
                for (var key in this.availability) {
                    if (this.availability.hasOwnProperty(key)) {
 
                        if (!this.availability[key]) {
                            let notAvail = moment(key);

                            if (date.isSame(notAvail, 'day')) {
                                result = true;
                            }
                        }
                    }
                }

                if (result) {
                    classes.disabled = true;
                }
            }
            
            return classes
        },

        openCalendar() {
            if (this.$refs.picker.open) {
                this.$refs.picker.togglePicker();
            } else {
                this.loadingAvailability = true;
                this.getAvailability();
            }
        },

        toogleAvailability(open) {
            
        },

        getAvailability() {
            this.$axios.post('/actions/newbook/search/get-availability', { 
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
                categoryID: this.room.category_id
            }).then(res => {
                let availability = [];
                availability = res.data.data[Object.keys(res.data.data)[0]];

                let dates = [];
                Object.keys(availability.sites_available).forEach(k => {
                    dates.push(availability.sites_available[k]);
                })

                this.availability = availability['sites_available'];
                this.loadingAvailability = false;
                this.$refs.picker.togglePicker();
            });
        
        },

        getPricePerNigth(tariff) {
            let tariffs = _.flatMap(tariff.tariffs_quoted, o => o);

            let quote = _.minBy(tariffs, function (o) {
                return o.calculated_amount;
            });

            if (quote) {
                return quote.calculated_amount;
            }

            return 0;
        },
    }
}
</script>