var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
        $('.collapsible.active img').toggleClass('rotate-0 rotate-45');
        $('.content').css('max-height', '');
        $('.collapsible.active').removeClass('active');
        
        this.classList.toggle("active");
        if (this.querySelector("img")) {
            this.querySelector("img").classList.toggle("rotate-45");
            this.querySelector("img").classList.toggle("rotate-0");
        }
        
        if (this.dataset.contentPosition === 'next') {
            var content = this.nextElementSibling;
        } else if (this.dataset.contentPosition === 'previous') {
            var content = this.previousElementSibling;
        }
        
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    });
}