<template>
    <form method="POST" action="/accommodation/book" id="booking-search">
        <slot></slot>
        <input type="hidden" name="startDate" id="startDate" v-model="dateRange.startDate">
        <input type="hidden" name="endDate" id="endDate" v-model="dateRange.endDate">
        <div class="flex flex-wrap justify-center pt-5 lg:pt-27 pb-0 lg:pb-22 px-5 lg:px-15">
            <div class="w-full lg:w-3/12 px-5">
                <label for="" class="form-label border-0">Check In - Check Out</label>

                <date-range-picker
                        class="form-field border pl-14 min-h-42"
                        ref="picker"
                        :opens="'right'"
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                        :singleDatePicker=false
                        :timePicker=false
                        :showWeekNumbers=false
                        :showDropdowns=false
                        :autoApply=true
                        v-model="dateRange"
                        :ranges=false
                        :linkedCalendars=false
                        >
                    <div slot="input" slot-scope="picker" style="width: 100%; overflow-x: hidden; white-space: nowrap;">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </div>
                </date-range-picker>
            </div>

            <div class="w-6/12 lg:w-3/12 px-5">
                <label for="" class="form-label border-0">Guests</label>

                <button type="button" class="tooltip form-field border px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" :class="{ 'open': toolTipOpen }" @click="toolTipOpen = !toolTipOpen"><span
                        class="quest-total">{{ adults + children + infants }}</span>
                    <span class="tooltiptext" @click.stop>
                        <div class="flex flex-no-wrap"> 
                            <label for="" class="form-label border-0 capitalize text-left pl-10 my-auto">Adults</label>

                            <div class="custom-number-input">
                                <input type="number"
                                    class="form-field border-0 px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" required
                                    value="1" min="1" v-model="adults" name="adults" ref="adults"/>
                                <div class="quantity-nav">
                                    <div class="quantity-button quantity-up" @click="increase('adults')">+</div
                                    ><div class="quantity-button quantity-down" @click="decrease('adults')">-</div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-no-wrap">
                            <label for="" class="form-label border-0 capitalize text-left pl-10 my-auto">Children</label>

                            <div class="custom-number-input">
                                <input type="number"
                                    class="form-field border-0 px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" required
                                    value="0" min="0" v-model="children" name="children" ref="children"/>
                                <div class="quantity-nav"><div class="quantity-button quantity-up" @click="increase('children')">+</div><div class="quantity-button quantity-down" @click="decrease('children')">-</div></div>
                            </div>
                        </div>

                        <div class="flex flex-no-wrap">
                            <label for="" class="form-label border-0 capitalize text-left pl-10 my-auto">Infants</label>

                            <div class="custom-number-input">
                                <input type="number"
                                    class="form-field border-0 px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" required
                                    value="0" min="0" v-model="infants" name="infants" ref="infants"/>
                                <div class="quantity-nav"><div class="quantity-button quantity-up" @click="increase('infants')">+</div><div class="quantity-button quantity-down" @click="decrease('infants')">-</div></div>
                            </div>
                        </div>
                    </span>
                </button>
            </div>
            <div class="w-6/12 lg:w-3/12 px-5">
                <label for="" class="form-label border-0">Features</label>

                <button type="button" class="tooltip multi-select form-field border px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9"><span class="total">{{ features.length }}</span> Selected
                    <span class="tooltiptext">
                        <div class="flex flex-no-wrap" v-for="feature in featuresList" :key="feature.id">
                            <label class="checkbox-container form-label border-0 capitalize text-left pl-10 my-auto">{{ feature.name }}
                                <input type="checkbox" v-model="features" name="features[]" :value="feature.id">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </span>
                </button>

            </div>
            <div class="w-full lg:w-3/12 px-5 self-end">
                <button type="submit" class="btn btn-red mb-10 mt-3 lg:mb-20 lg:mt-0 w-full pt-13 pb-11">Check Rates</button>
            </div>
        </div>
    </form>
</template>

<script>
import Common from './mixins/common';
import DateRangePicker from 'vue2-daterange-picker';

export default {
    name: 'search',

    mixins: [Common],

    components: {
        DateRangePicker
    },

    data() {
        return {
            featuresList: [
                {id: 3, name: 'Bath'},
                {id: 4, name: 'Cooking Facilities'},
                {id: 6, name: 'Mountain View'},
                {id: 7, name: 'Lake View'},
                {id: 8, name: 'Garden View'}
            ],
            toolTipOpen: false,

            adults: this.$store.getters.filters.adults,
            children: this.$store.getters.filters.children,
            infants: this.$store.getters.filters.infants,
            feature: this.$store.getters.filters.feature,
            features: [],

            dateRange: {
                startDate: this.$store.getters.filters.start_date,
                endDate: this.$store.getters.filters.end_date
            },
        };
    },

    created() {
        // this.$axios.get('/actions/newbook/search/get-features-list')
        //         .then(response => this.featuresList = response.data.data);
    },

    methods: {
        submit(event) {
            let formData = new FormData(event.target);

            formData.append('startDate', this.dateRange.startDate);
            formData.append('endDate', this.dateRange.endDate);
        }
    }
}
</script>