import moment from "moment";
import { isNumber } from "util";

export default {
    methods: {
        scrollTop() {
            $('html, body').stop().animate({
                scrollTop: $('#book').offset().top - 100
            }, 400);
        },

        // Increase number inputs
        increase(vModel) {
            this[vModel] ++;
            let obj = {};
            obj[vModel] = this[vModel];
            this.$store.commit('setFilter', obj);
        },

        // Decrease number inputs
        decrease(vModel) {
            if (this[vModel] > this.$refs[vModel].min) {
                this[vModel] --;
                let obj = {};
                obj[vModel] = this[vModel];
                this.$store.commit('setFilter', obj);
            }
        },

        cropImage(img) {
            let url = new URL(img);
            let croppedImg = url.origin + '/crop_1200_900' + url.pathname;
            return croppedImg;
        }
    },

    filters: {
        formatDate(value, format) {
            if (!value) return '';

            if (!format) {
                format = 'll';
            }
            
            return moment(value).format(format);
        },

        formatMoney(value) {
            if (!value) return '';

            if (!isNumber(value)) {
                value = parseFloat(value);
            }

            return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}NZD`;
        },

        date(value) {
            let options = {year: 'numeric', month: 'short', day: 'numeric'};
            return Intl.DateTimeFormat('en-US', options).format(value)
        },

        stripHtml(value) {
            let div = document.createElement('div');
            div.innerHTML = value;

            return div.textContent || div.innerText;
        }
    }
}