var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "book" } },
    [
      _c(
        _vm.componentName,
        {
          tag: "component",
          attrs: { filters: _vm.filters },
          on: {
            "show-component": function($event) {
              return _vm.showComponent($event)
            }
          }
        },
        [
          _c(
            "span",
            { attrs: { slot: "noResults" }, slot: "noResults" },
            [_vm._t("noResults")],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }