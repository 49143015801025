import $ from 'jquery'

const form = document.getElementById('function-enquiry')

if (form) {
  // Submit the form to Triple seat leads API then to Craft
  form.addEventListener('submit', e => {
    e.preventDefault()

    // helper function to get a field value
    const formValue = selector => form.querySelector(selector).value

    const data = {
      lead: {
        first_name: formValue('#fields-firstName'),
        last_name: formValue('#fields-lastName'),
        phone_number: formValue('#fields-phoneNumber'),
        email_address: formValue('#fields-email'),
        contact_preference: formValue('#fields-fe-contactPreference'),
        company: formValue('#fields-company'),
        event_description: formValue('#fields-fe-details'),
        event_date: formValue('#fields-fe-eventDate'),
        start_time: formValue('#fields-fe-startTime'),
        end_time: formValue('#fields-fe-endTime'),
        guest_count: formValue('#fields-numberOfPeople'),
        additional_information: formValue('#fields-fe-addInfo'),
      },
    }

    $.ajax('https://api.tripleseat.com/v1/leads/create.js?public_key=c941842af205890ad116d4b1e3726fcc3e86f4b2',
      {
        data: data,
        dataType:'JSONP',
        crossDomain: true,
        beforeSend() {
          form.querySelector('button').classList.add('loading')
        },
        success(data) {
          if (data.errors != undefined) {
            // handle errors
            console.error('Failed sending to Triple Seat')
          } else {
            // submit for to Craft
            form.submit()
        }
        }
      }).catch(err => {
        form.querySelector('button').classList.remove('loading')
        console.error('Failed to submit function enquiry form:', err)
      })
  })
}
