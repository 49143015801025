<template>
    <div id="search">
        <div class="flex flex-wrap py-30 md:py-60">
            <div class="w-full lg:w-4/12 lg:pr-20">
                <label for="" class="form-label border-0">Arrival / Departure Dates</label>

                <date-range-picker
                        class="form-field border pl-14 min-h-42"
                        ref="picker"
                        :opens="'right'"
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                        :singleDatePicker=false
                        :timePicker=false
                        :showWeekNumbers=false
                        :showDropdowns=false
                        :autoApply=true
                        v-model="dateRange"
                        :ranges=false
                        :linkedCalendars=false
                    >

                    <div slot="input" slot-scope="picker" style="width: 100%;">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </div>
                </date-range-picker>
            </div>

            <div class="w-6/12 lg:w-4/12 pr-5 lg:px-20">
                <label for="" class="form-label border-0">Guests</label>

                <button class="tooltip form-field border px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" :class="{ 'open': toolTipOpen }" @click="toolTipOpen = !toolTipOpen"><span
                        class="quest-total">{{ adults + children + infants }}</span>
                    <span class="tooltiptext" @click.stop>
                        <div class="flex flex-no-wrap"> 
                            <label for="" class="form-label border-0 capitalize text-left pl-10 my-auto">Adults</label>

                            <div class="custom-number-input">
                                <input type="number"
                                    class="form-field border-0 px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" required
                                    value="1" min="1" v-model="adults" disabled ref="adults"/>
                                <div class="quantity-nav">
                                    <div class="quantity-button quantity-up" @click="increase('adults')">+</div
                                    ><div class="quantity-button quantity-down" @click="decrease('adults')">-</div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-no-wrap">
                            <label for="" class="form-label border-0 capitalize text-left pl-10 my-auto">Children</label>

                            <div class="custom-number-input">
                                <input type="number"
                                    class="form-field border-0 px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" required
                                    value="0" min="0" v-model="children" disabled ref="children"/>
                                <div class="quantity-nav"><div class="quantity-button quantity-up" @click="increase('children')">+</div><div class="quantity-button quantity-down" @click="decrease('children')">-</div></div>
                            </div>
                        </div>

                        <div class="flex flex-no-wrap">
                            <label for="" class="form-label border-0 capitalize text-left pl-10 my-auto">Infants</label>

                            <div class="custom-number-input">
                                <input type="number"
                                    class="form-field border-0 px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9" required
                                    value="0" min="0" v-model="infants" disabled ref="infants"/>
                                <div class="quantity-nav"><div class="quantity-button quantity-up" @click="increase('infants')">+</div><div class="quantity-button quantity-down" @click="decrease('infants')">-</div></div>
                            </div>
                        </div>
                    </span>
                </button>
            </div>

            <div class="w-6/12 lg:w-4/12 pl-5 lg:pl-20">
                <label for="" class="form-label border-0">Features</label>

                <button class="tooltip multi-select form-field border px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9"><span class="total">{{ features.length }}</span> Selected
                    <span class="tooltiptext">
                        <div class="flex flex-no-wrap" v-for="feature in featuresList" :key="feature.id">
                            <label class="checkbox-container form-label border-0 capitalize text-left pl-10 my-auto">{{ feature.name }}
                                <input type="checkbox" name="radio" v-model="features" :value="feature.id">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </span>
                </button>
            </div>
        </div>

        <div class="" :class="{ 'show': rooms.length != 0, 'hide': rooms.length == 0  }">
            <booking-room v-for="room in filteredList" :room="room" :category="getCategory(room.category_id)" :key="room.category_id" v-on:show-component="showComponent"></booking-room>
        </div>

        <div v-if="rooms.length == 0">
            <button class="btn btn-red loading big"></button>
        </div>

        <div v-if="filteredList.length == 0 && searchCompleted" class="h-40-vh flex items-center">
            <h4 class="body-copy mx-auto text-red text-center markdown"><slot name="noResults"></slot></h4>
        </div>
        
        <a href="#details" class="btn btn-red sticky bottom-0 right-0 z-20 hover:bg-red hover:text-white hover:opacity-75" :class="{ 'hidden': cart.items.length == 0 }" @click="showComponent()">Back to Cart</a>
    </div>
</template>

<script>
// Import 
import axios from 'axios';
import BookingRoom from './BookingRoom.vue';
import Common from './mixins/common';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

export default {
    name: 'booking-search',

    props: ['filters'],

    components: {
        BookingRoom,
        DateRangePicker
    },

    mixins: [Common],

    created() {
        this.loadData();

        if (this.$store.getters.filters.read) {
            this.adults = parseFloat(this.filters.adults);
            this.children = parseFloat(this.filters.children);
            this.infants = parseFloat(this.filters.infants);
            this.features = this.filters.features;
            this.dateRange.startDate = this.filters.startDate ? moment(new Date(this.filters.startDate)) : moment();
            this.dateRange.endDate = this.filters.endDate ? moment(new Date(this.filters.endDate)) : moment().add(3, 'days');

            this.$store.commit('setFilter', {
                adults: this.adults,
                children: this.children,
                infants: this.infants,
                read: false
            });
        }
    },

    data() {
        return {
            adults: this.$store.getters.filters.adults,
            children: this.$store.getters.filters.children,
            infants: this.$store.getters.filters.infants,
            feature: this.$store.getters.filters.feature,
            cart: this.$store.getters.cart,

            dateRange: {
                startDate: moment(this.$store.getters.filters.start_date),
                endDate: moment(this.$store.getters.filters.end_date)
            },
            
            featuresList: [
                {id: 3, name: 'Bath'},
                {id: 4, name: 'Cooking Facilities'},
                {id: 6, name: 'Mountain View'},
                {id: 7, name: 'Lake View'},
                {id: 8, name: 'Garden View'}
            ],
            features: [],
            accommodation_category_list: [],
            rooms: [],

            toolTipOpen: false,

            searchCompleted: false
        }
    },

    watch: {
        adults(value) {
            this.searchBookings();
        },
        children(value) {
            this.searchBookings();
        },
        infants(value) {
            this.searchBookings();
        },
        'dateRange.startDate': function (value) {
            this.$store.commit('setFilter', {start_date: moment(value).format('YYYY-MM-DD')});
        },
        'dateRange.endDate': function (value) {
            this.$store.commit('setFilter', {end_date: moment(value).format('YYYY-MM-DD')});
            this.searchBookings();
        }
    },

    computed: {
        filteredList() {
            let rooms = [];
            let list = Object.keys(this.rooms).map(key => this.rooms[key]);

            list.forEach(room => {
                room.category = this.accommodation_category_list.filter(category => category.category_id == room.category_id)[0];
                let include = true;
                
                Object.keys(room.sites_available).forEach(k => {
                    if (!room.sites_available[k]) {
                        include = false;
                    }
                });

                if (include) {
                    if (this.features.length > 0) {
                        let roomFeatures = _.flatMap(room.category.features, feature => parseInt(feature.feature_id));

                        if (this.features.every(item => roomFeatures.includes(item))) {
                            rooms.push(room);
                        }
                    } else {
                        rooms.push(room);
                    }
                }
            });

            rooms.sort((a, b) => (a.sort > b.sort) ? 1 : -1);

            return rooms;
        }
    },

    methods: {
        async loadData() {
            // this.$axios.get('/actions/newbook/search/get-features-list')
            //     .then(response => this.featuresList = response.data.data);

            let category = await this.$axios.get('/actions/newbook/search/accomodation-category-list');
            this.accommodation_category_list = category.data.data;
            
            this.searchBookings();
        },

        getCategory(id) {
            return this.accommodation_category_list.filter(category => category.category_id == id)[0];
        },

        showComponent() {
            this.$emit('show-component', 'BookingCheckout');
        },

        searchBookings: _.debounce(function () {
            this.searchCompleted = false;
            this.rooms = [];
            this.$axios.post('/actions/newbook/search', { 
                adults: this.adults,
                children: this.children,
                infants: this.infants,
                startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD')
            }).then(res => {
                this.searchCompleted = true;
                this.rooms = res.data.data;
            });
        }, 400)
    }

}
</script>