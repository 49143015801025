// Show and hide suppliers on restaurant page link click
$(document).ready(function () {

    $('[id*="suppliers"] li h4').click(function () {
        let id = $(this).data('id');
        $('[id*="suppliers"] li h4').removeClass('opacity-100').addClass('opacity-50');
        $(this).addClass('opacity-100').removeClass('opacity-50');

        // $('.reveal').css('display', 'none');
        $('.reveal').addClass('hidden').removeClass('block');

        // $('div[data-id="' + id + '"]').css('display', 'contents');
        $('.reveal[data-id="' + id + '"]').addClass('block').removeClass('hidden');

    });

});