let tabSelectors = document.getElementsByClassName("tab-selector");
let i;

for (i = 0; i < tabSelectors.length; i++) {
    tabSelectors[i].addEventListener("click", function () {
        let openTab = document.getElementsByClassName("active");
        openTab[0].classList.remove("active");

        $('li.nav-item').removeClass('border-b border-green');
 
        this.querySelector('li.nav-item').classList.add('border-b', 'border-green')

        let tab = document.getElementById(this.dataset.tabId);
        tab.classList.add("active");
        
        $('html, body').stop().animate({
            scrollTop: $('#' + this.dataset.tabId).offset().top
        }, 400);

        AOS.init({
            offset: 0,
            duration: 2000,
            once: true,
        });
    });
}