<template>
	<div id="checkout" class="mb-20 md:mb-88">
		<div class="flex flex-wrap justify-center py-30 3md:py-40 mb-20 md:mb-30 border-b border-red">
			<div class="w-full md:w-4/12 lg:w-3/12 text-left pl-50 md:text-center md:pl-0">
				<a href="#details" class="step body-copy text-red opacity-50" :class="{ 'opacity-100': bookingDetails }"
					data-index=" 1" @click="bookingDetails = true; addons = false; paymentSection = false; scrollTop()">Booking
					Details</a>
			</div>

			<div class="w-full md:w-4/12 lg:w-3/12 text-left pt-30 md:pt-0 pl-50 md:text-center md:pl-0">
				<a href="#add-ons" class="step body-copy text-red opacity-50" :class="{ 'opacity-100': addons }" data-index=" 2"
					@click="addons = true; bookingDetails = false; paymentSection = false; scrollTop()">Booking Add-ons</a>
			</div>

			<div class="w-full md:w-4/12 lg:w-3/12 text-left pt-30 md:pt-0 pl-50 md:text-center md:pl-0">
				<a href="#checkout" class="step body-copy text-red opacity-50" :class="{ 'opacity-100': paymentSection }"
					data-index=" 3" @click="paymentSection = true; bookingDetails = false; addons = false; scrollTop()">Check
					Out</a>
			</div>
		</div>

		<div class="flex flex-wrap justify-end" :class="{ hidden: !checkoutShown }">
			<div class="w-full md:w-7/12 lg:w-9/12 xl:w-8/12 md:pr-10 lg:pr-20" :class="{ hidden: !bookingDetails }">
				<div class="flex flex-wrap p-20 border border-red mb-20" v-for="(room, index) in cart.items" :key="index">
					<div class="w-full lg:w-1/2">
						<!-- swiper -->
						<div class="swiper-room-gallery-container w-full overflow-x-hidden overflow-y-hidden relative" ref="gallery">
							<div class="swiper-wrapper">
								<div class="swiper-slide flex" v-for="image in room.category.images" :key="image.id">
									<div class="w-full relative pb-3/4">
										<img :src="cropImage(image.image_url)" :alt="image.image_type_name"
											class="w-full absolute object-cover h-full top-0 left-0">
									</div>
								</div>
							</div>
							<div class="swiper-button-prev focus:outline-none hover:opacity-50"
								style="width: 12px; height: 20px; background-size: 12px 20px;"></div>
							<div class="swiper-button-next focus:outline-none hover:opacity-50"
								style="width: 12px; height: 20px; background-size: 12px 20px;"></div>
						</div>
					</div>
					<div class="w-full lg:w-1/2 lg:pl-40 pt-10 lg:pt-0">
						<div class="flex justify-between w-full pb-10">
							<h2 class="secondary-heading text-red pb-20">{{ room.category_name }}</h2>
							<button class="btn text-red text-20 pt-8 pr-0 mb-auto" @click="removeRoom(index)">&#10005;</button>
						</div>

						<div class="flex justify-between w-full pb-10">
							<p class="sub-heading capitalize text-red">Arrival</p>
							<p class="body-copy text-red w-8/12">{{ room.period_from | formatDate }}</p>
						</div>
						<div class="flex justify-between w-full pb-10">
							<p class="sub-heading capitalize text-red">Departure</p>
							<p class="body-copy text-red w-8/12">{{ room.period_to | formatDate }}</p>
						</div>
						<div class="flex justify-between w-full pb-10">
							<p class="sub-heading capitalize text-red">Nights</p>
							<p class="body-copy text-red w-8/12">{{ getNumberOfNigths(room.period_from, room.period_to) }} Nights</p>
						</div>
						<div class="flex justify-between w-full pb-10">
							<p class="sub-heading capitalize text-red">Guests</p>
							<p class="body-copy text-red w-8/12">{{ room.adults }} Adults <br>{{ room.children }} Children <br>{{
								room.infants }} Infants</p>
						</div>
						<div class="flex justify-between w-full pb-10">
							<p class="sub-heading capitalize text-red">Tariff</p>
							<p class="body-copy text-red w-8/12">{{ room.tariff.tariff_label }}</p>
						</div>
						<div class="flex justify-between w-full">
							<p class="sub-heading capitalize text-red">Total</p>
							<p class="body-copy text-red w-8/12">{{ room.tariff.tariff_total | formatMoney }}</p>
						</div>
					</div>
				</div>

				<div class="flex flex-wrap border border-red justify-between mt-15 px-20 py-10">
					<div class="w-1/2 lg:w-4/12">
						<a href="#" class="btn btn-red-outline w-full block text-center" @click="showSearch()">Change Booking</a>
					</div>
					<div class="w-1/2 lg:w-4/12">
						<a href="#add-ons" class="btn btn-red-outline w-full border-l-0 lg:border-l block ml-auto text-center"
							@click="paymentSection = false; bookingDetails = false; addons = true; scrollTop()">Next Step <img
								src="/assets/icons/arrow-red.png" alt="" class="inline-block pb-3" width="18"></a>
					</div>
				</div>
			</div>

			<div class="w-full md:w-7/12 lg:w-9/12 xl:w-8/12 md:pr-10 lg:pr-20" :class="{ hidden: !addons }">
				<div class="flex flex-wrap justify-end" v-for="(chunk, index) in chunkedInventoryList" :key="index">
					<div class="w-full lg:w-5/12 pb-20" :class="{ 'lg:pr-10': chunkIndex == 0, 'lg:pl-10': chunkIndex == 1 }"
						v-for="(addon, chunkIndex) in chunk" :key="addon.id">
						<div class="border border-red p-20">
							<h2 class="secondary-heading text-red pb-20 xl:min-h-92">{{ addon.name }}</h2>
							<img :src="cropImage(addon.images[0])" alt="" class="pb-30 w-full"
								v-if="addon.images && addon.images.length > 0">
							<img src="/assets/sherwood.jpg" alt="" class="pb-30 w-full" v-else>

							<p class="body-copy text-red pb-30">
								<span class="content inline-block max-h-42" v-html="addon.additional_information"></span>
								<a class="collapsible cursor-pointer btn-link text-red border-red" @click="toggleText($event)">More
									Details</a>
							</p>
							<div class="custom-number-input relative mb-40">
								<label for="" class="form-label border-0 absolute top-5 left-12">Quantity:</label>
								<input type="number"
									class="form-field border px-8 md:px-17 pt-10 md:pt-11 pb-8 md:pb-9 text-center w-8/12 mb-15" required
									value="0" min="0" v-model="addon.qty" disabled ref="addon.qty" />
								<div class="quantity-nav">
									<div class="quantity-button quantity-up" @click="addInventory(addon)">+</div>
									<div class="quantity-button quantity-down" @click="decreaseInventory(addon)">-</div>
								</div>
							</div>

							<!-- <button class="btn btn-red-outline w-7/12 mt-15" @click="addInventory(addon)">+ Add to cart</button> -->
						</div>
					</div>
				</div>

				<div class="flex flex-wrap justify-end mt-15">
					<div class="w-full lg:w-10/12 border border-red px-20 py-10">
						<div class="flex flex-wrap justify-between">
							<div class="w-1/2 lg:w-5/12">
								<a href="#" class="btn btn-red-outline w-full block text-center" @click="showSearch()">Change Booking</a>
							</div>
							<div class="w-1/2 lg:w-5/12">
								<a href="#checkout" class="btn btn-red-outline w-full border-l-0 lg:border-l block text-center"
									@click="paymentSection = true; bookingDetails = false; addons = false; scrollTop();">Next Step <img
										src="/assets/icons/arrow-red.png" alt="" class="inline-block pb-3" width="18"></a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="w-full md:w-7/12 xl:w-6/12 md:pr-10 lg:pr-20" :class="{ hidden: !paymentSection }">
				<div class="flex flex-wrap p-20 mb-20 border border-red" v-if="errors.length > 0">
					<div class="w-full">
						<h4 class="sub-heading text-red pb-30">Error</h4>
						<p class="body-copy text-red pb-10" v-for="(error, index) in errors" :key="index">- {{ error }}</p>
					</div>
				</div>
				<form method="POST" id="guestForm" class="w-full">
					<div class="flex flex-wrap p-20 border border-red w-full">
						<div class="w-full pb-30">
							<h4 class="sub-heading text-red">Booking Details</h4>
						</div>

						<div class="w-1/2 pr-10 lg:pr-20 border-b border-red pb-30">
							<label for="" class="form-label border-0">ETA</label>

							<select class="form-field vue-select" v-model="cart.eta" required>
								<option value="" selected disabled>Select your ETA</option>
								<option value="14:00">BEFORE 3PM (subject to availability)</option>
								<option value="15:00">3PM</option>
								<option value="16:00">4PM</option>
								<option value="17:00">AFTER 5PM</option>
							</select>
						</div>
						<div class="w-1/2 pl-10 lg:pl-20 border-b border-red pb-30">
							<label for="" class="form-label border-0">Booking Reason</label>

							<select class="form-field vue-select mb-30" required v-model="cart.reason">
								<option v-for="reason in reasons" :value="reason.id" :key="reason.id">{{ reason.name }}</option>
							</select>
						</div>

						<div v-for="(room, index) in cart.items" :key="index" class="w-full">
							<div class="w-full py-30">
								<h4 class="sub-heading text-red">{{ room.category_name }}</h4>
							</div>

							<div class="flex flex-wrap">
								<div class="w-1/2 pr-10 lg:pr-20 border-b border-red pb-30">
									<div class="w-full py-30">
										<h4 class="sub-heading text-red">Guest Details {{ index + 1 }}</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="First Name"
											v-model="room.guest.guest_firstname" required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="Last Name"
											v-model="room.guest.guest_lastname" required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="email" class="form-field border-b" placeholder="Email" v-model="room.guest.guest_email"
											required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="Primary Phone"
											v-model="room.guest.guest_phone" required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="Secondary Phone"
											v-model="room.guest.secondary_name">
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
								</div>

								<div class="w-1/2 pl-10 lg:pl-20 border-b border-red pb-30">
									<div class="w-full py-30">
										<h4 class="sub-heading text-red"><br></h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="Address"
											v-model="room.guest.address_street" required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="City" v-model="room.guest.address_city"
											required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="State"
											v-model="room.guest.address_state_name">
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="relative">
										<input type="text" class="form-field border-b" placeholder="Post Code"
											v-model="room.guest.address_postcode" required>
										<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
											:class="{ 'hide': true }">Invalid card number</h4>
									</div>
									<div class="w-full relative">
										<select v-model="room.guest.address_country_name" class="form-field vue-select mb-30" required>
											<option value="" disabled selected>Select country</option>
											<option value="1">Afghanistan</option>
											<option value="639">Åland Islands</option>
											<option value="7">Albania</option>
											<option value="2">Algeria</option>
											<option value="3">American Samoa</option>
											<option value="4">Andorra</option>
											<option value="5">Angola</option>
											<option value="640">Anguilla</option>
											<option value="8">Antarctica</option>
											<option value="641">Antigua and Barbuda</option>
											<option value="10">Argentina</option>
											<option value="11">Armenia</option>
											<option value="12">Aruba</option>
											<option value="13">Australia</option>
											<option value="14">Austria</option>
											<option value="15">Azerbaijan</option>
											<option value="243">Bahamas (the)</option>
											<option value="32">Bahrain</option>
											<option value="17">Bangladesh</option>
											<option value="237">Barbados</option>
											<option value="642">Belarus</option>
											<option value="19">Belgium</option>
											<option value="20">Belize</option>
											<option value="21">Benin</option>
											<option value="643">Bermuda</option>
											<option value="35">Bhutan</option>
											<option value="24">Bolivia (Plurinational State of)</option>
											<option value="644">Bonaire, Sint Eustatius and Saba</option>
											<option value="25">Bosnia and Herzegovina</option>
											<option value="26">Botswana</option>
											<option value="645">Bouvet Island</option>
											<option value="28">Brazil</option>
											<option value="646">British Indian Ocean Territory (the)</option>
											<option value="30">Brunei Darussalam</option>
											<option value="31">Bulgaria</option>
											<option value="33">Burkina Faso</option>
											<option value="34">Burundi</option>
											<option value="39">Cabo Verde</option>
											<option value="36">Cambodia</option>
											<option value="37">Cameroon</option>
											<option value="38">Canada</option>
											<option value="647">Cayman Islands (the)</option>
											<option value="41">Central African Republic (the)</option>
											<option value="42">Chad</option>
											<option value="43">Chile</option>
											<option value="44">China</option>
											<option value="648">Christmas Island</option>
											<option value="649">Cocos (Keeling) Islands (the)</option>
											<option value="47">Colombia</option>
											<option value="108">Comoros (the)</option>
											<option value="236">Congo (the Democratic Republic of the)</option>
											<option value="48">Congo (the)</option>
											<option value="49">Cook Islands (the)</option>
											<option value="50">Costa Rica</option>
											<option value="651">Côte d'Ivoire</option>
											<option value="90">Croatia</option>
											<option value="51">Cuba</option>
											<option value="652">Curaçao</option>
											<option value="52">Cyprus</option>
											<option value="53">Czech Republic (the)</option>
											<option value="55">Denmark</option>
											<option value="54">Djibouti</option>
											<option value="653">Dominica</option>
											<option value="246">Dominican Republic (the)</option>
											<option value="57">Ecuador</option>
											<option value="58">Egypt</option>
											<option value="59">El Salvador</option>
											<option value="60">Equatorial Guinea</option>
											<option value="654">Eritrea</option>
											<option value="61">Estonia</option>
											<option value="62">Ethiopia</option>
											<option value="64">Falkland Islands (the) [Malvinas]</option>
											<option value="65">Faroe Islands (the)</option>
											<option value="66">Fiji</option>
											<option value="63">Finland</option>
											<option value="67">France</option>
											<option value="655">French Guiana</option>
											<option value="166">French Polynesia</option>
											<option value="656">French Southern Territories (the)</option>
											<option value="70">Gabon</option>
											<option value="79">Gambia (the)</option>
											<option value="71">Georgia</option>
											<option value="72">Germany</option>
											<option value="73">Ghana</option>
											<option value="74">Gibraltar</option>
											<option value="76">Greece</option>
											<option value="78">Greenland</option>
											<option value="657">Grenada</option>
											<option value="81">Guadeloupe</option>
											<option value="658">Guam</option>
											<option value="77">Guatemala</option>
											<option value="244">Guernsey</option>
											<option value="83">Guinea</option>
											<option value="84">Guinea-Bissau</option>
											<option value="85">Guyana</option>
											<option value="87">Haiti</option>
											<option value="659">Heard Island and McDonald Islands</option>
											<option value="224">Holy See (the)</option>
											<option value="89">Honduras</option>
											<option value="86">Hong Kong</option>
											<option value="91">Hungary</option>
											<option value="92">Iceland</option>
											<option value="93">India</option>
											<option value="94">Indonesia</option>
											<option value="95">Iran (Islamic Republic of)</option>
											<option value="96">Iraq</option>
											<option value="97">Ireland</option>
											<option value="245">Isle of Man</option>
											<option value="98">Israel</option>
											<option value="99">Italy</option>
											<option value="238">Jamaica</option>
											<option value="102">Japan</option>
											<option value="660">Jersey</option>
											<option value="103">Jordan</option>
											<option value="104">Kazakhstan</option>
											<option value="105">Kenya</option>
											<option value="107">Kiribati</option>
											<option value="111">Kuwait</option>
											<option value="106">Kyrgyzstan</option>
											<option value="112">Lao People's Democratic Republic (the)</option>
											<option value="113">Latvia</option>
											<option value="114">Lebanon</option>
											<option value="115">Lesotho</option>
											<option value="116">Liberia</option>
											<option value="117">Libya</option>
											<option value="118">Liechtenstein</option>
											<option value="119">Lithuania</option>
											<option value="120">Luxembourg</option>
											<option value="121">Macao</option>
											<option value="661">Macedonia (the former Yugoslav Republic of)</option>
											<option value="122">Madagascar</option>
											<option value="123">Malawi</option>
											<option value="124">Malaysia</option>
											<option value="125">Maldives</option>
											<option value="126">Mali</option>
											<option value="136">Malta</option>
											<option value="127">Marshall Islands (the)</option>
											<option value="128">Martinique</option>
											<option value="129">Mauritania</option>
											<option value="130">Mauritius</option>
											<option value="662">Mayotte</option>
											<option value="131">Mexico</option>
											<option value="132">Micronesia (Federated States of)</option>
											<option value="133">Moldova (the Republic of)</option>
											<option value="134">Monaco</option>
											<option value="140">Mongolia</option>
											<option value="663">Montenegro</option>
											<option value="664">Montserrat</option>
											<option value="137">Morocco</option>
											<option value="138">Mozambique</option>
											<option value="139">Myanmar</option>
											<option value="141">Namibia</option>
											<option value="142">Nauru</option>
											<option value="143">Nepal</option>
											<option value="145">Netherlands (the)</option>
											<option value="147">New Caledonia</option>
											<option value="152">New Zealand</option>
											<option value="148">Nicaragua</option>
											<option value="149">Niger (the)</option>
											<option value="150">Nigeria</option>
											<option value="151">Niue</option>
											<option value="153">Norfolk Island</option>
											<option value="109">North Korea (the Democratic People's Republic of)</option>
											<option value="665">Northern Mariana Islands (the)</option>
											<option value="155">Norway</option>
											<option value="156">Oman</option>
											<option value="157">Pakistan</option>
											<option value="158">Palau</option>
											<option value="666">Palestine, State of</option>
											<option value="159">Panama</option>
											<option value="160">Papua New Guinea</option>
											<option value="161">Paraguay</option>
											<option value="162">Peru</option>
											<option value="163">Philippines (the)</option>
											<option value="667">Pitcairn</option>
											<option value="165">Poland</option>
											<option value="167">Portugal</option>
											<option value="668">Puerto Rico</option>
											<option value="169">Qatar</option>
											<option value="170">Réunion</option>
											<option value="171">Romania</option>
											<option value="172">Russian Federation (the)</option>
											<option value="173">Rwanda</option>
											<option value="669">Saint Barthélemy</option>
											<option value="191">Saint Helena, Ascension and Tristan da Cunha</option>
											<option value="670">Saint Kitts and Nevis</option>
											<option value="671">Saint Lucia</option>
											<option value="672">Saint Martin (French part)</option>
											<option value="192">Saint Pierre and Miquelon</option>
											<option value="673">Saint Vincent and the Grenadines</option>
											<option value="230">Samoa</option>
											<option value="176">San Marino</option>
											<option value="193">Sao Tome and Principe</option>
											<option value="177">Saudi Arabia</option>
											<option value="178">Senegal</option>
											<option value="232">Serbia</option>
											<option value="179">Seychelles</option>
											<option value="181">Sierra Leone</option>
											<option value="198">Singapore</option>
											<option value="675">Sint Maarten (Dutch part)</option>
											<option value="182">Slovakia</option>
											<option value="183">Slovenia</option>
											<option value="184">Solomon Islands</option>
											<option value="185">Somalia</option>
											<option value="187">South Africa</option>
											<option value="676">South Georgia and the South Sandwich Islands</option>
											<option value="110">South Korea (the Republic of)</option>
											<option value="677">South Sudan</option>
											<option value="189">Spain</option>
											<option value="190">Sri Lanka</option>
											<option value="180">Sudan (the)</option>
											<option value="186">Suriname</option>
											<option value="678">Svalbard and Jan Mayen</option>
											<option value="196">Swaziland</option>
											<option value="197">Sweden</option>
											<option value="199">Switzerland</option>
											<option value="200">Syrian Arab Republic</option>
											<option value="202">Taiwan</option>
											<option value="201">Tajikistan</option>
											<option value="203">Tanzania, United Republic of</option>
											<option value="205">Thailand</option>
											<option value="209">Timor-Leste</option>
											<option value="206">Togo</option>
											<option value="207">Tokelau</option>
											<option value="208">Tonga</option>
											<option value="679">Trinidad and Tobago</option>
											<option value="211">Tunisia</option>
											<option value="212">Turkey</option>
											<option value="213">Turkmenistan</option>
											<option value="680">Turks and Caicos Islands (the)</option>
											<option value="215">Tuvalu</option>
											<option value="216">Uganda</option>
											<option value="204">Ukraine</option>
											<option value="217">United Arab Emirates (the)</option>
											<option value="218">United Kingdom</option>
											<option value="681">United States Minor Outlying Islands (the)</option>
											<option value="219">United States of America</option>
											<option value="220">Uruguay</option>
											<option value="222">Uzbekistan</option>
											<option value="223">Vanuatu</option>
											<option value="225">Venezuela (Bolivarian Republic of)</option>
											<option value="226">Vietnam</option>
											<option value="682">Virgin Islands (British)</option>
											<option value="683">Virgin Islands (U.S.)</option>
											<option value="229">Wallis and Futuna</option>
											<option value="684">Western Sahara*</option>
											<option value="231">Yemen</option>
											<option value="234">Zambia</option>
											<option value="235">Zimbabwe</option>
										</select>
									</div>
								</div>
								<div class="w-full">

								</div>
							</div>
						</div>

						<textarea class="form-field border-t-0" placeholder="Comments" name="notes" id="fields-fe-details" rows="6"
							spellcheck="false" v-model="cart.notes"></textarea>

						<div class="w-full bg-white border border-red p-10 my-30">
							<p class="body-copy text-red uppercase max-w-full pb-40 text-center">Your Credit Card will be stored
								securely and charged as per booking terms and conditions (if applicable). <br /> Includes 2.5% Transaction
								Fee.</p>
							<p class="body-copy text-red uppercase text-center m-auto pb-20">Accepted Cards</p>
							<div class="flex flex-no-wrap justify-center pb-20">
								<div>
									<img src="/assets/icons/visa.png" alt="" width="50" class="mx-8 md:mx-15">
								</div>
								<div>
									<img src="/assets/icons/master-card.png" alt="" width="50" class="mx-8 md:mx-15">
								</div>
								<div>
									<img src="/assets/icons/american-express.png" alt="" width="50" class="mx-8 md:mx-15">
								</div>
							</div>
						</div>

						<div class="w-full pb-30">
							<h4 class="sub-heading text-red">Payment Details</h4>
						</div>

						<div class="flex flex-wrap">
							<div class="w-1/2 pr-10 lg:pr-20 relative">
								<input type="text" class="form-field border-b" v-mask="'#### #### #### ####'"
									placeholder="0000 0000 0000 0000" required v-model="cart.card_number">
								<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
									:class="{ 'hide': false }">Invalid card number</h4>
							</div>
							<div class="w-1/2 pl-10 lg:pl-20 relative">
								<input type="text" class="form-field border-b" placeholder="CVC" required v-model="cart.card_cvc">
								<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
									:class="{ 'hide': true }">Invalid cvc number</h4>
							</div>

							<div class="w-1/2 pr-10 lg:pr-20 relative">
								<input type="text" class="form-field border-b" placeholder="Name on the Card" required
									v-model="cart.card_name">
								<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
									:class="{ 'hide': true }">Invalid card name</h4>
							</div>
							<div class="w-1/2 pl-10 lg:pl-20 relative">
								<input type="text" class="form-field border-b" v-mask="'##/##'" placeholder="Expiry Date" required
									v-model="cart.card_expiry">
								<h4 class="feedback absolute body-copy bottom-0 left-0 text-12 text-bright-red show hidden"
									:class="{ 'hide': true }">Invalid expiry date</h4>
							</div>
						</div>

						<div class="w-full py-20">
							<input type="checkbox" id="marketing" name="marketing" v-model="newsletter">
							<label for="marketing" class="body-copy text-red pl-8">I/We agree to receive marketing emails</label>
						</div>
						<div class="w-full pb-30 relative">
							<input type="checkbox" id="terms_conditions" name="terms_conditions" value="1" required>
							<label for="terms_conditions" class="body-copy text-red pl-8">I/We have read & agree to the <a
									href="/terms-and-conditions">Terms and Conditions</a>, <a href="/terms-and-conditions">Cancellation
									Policy</a>, <a href="/terms-and-conditions">Refund Policy</a> of Sherwood</label>
							<h4 class="feedback absolute body-copy bottom-8 left-0 text-12 text-bright-red show hidden"
								:class="{ 'hide': true }">Invalid card number</h4>
						</div>

						<button type="button" class="btn btn-red w-full" :class="{ 'loading': loading }" :disabled="loading"
							@click="placeBooking()">Place Booking</button>


					</div>
				</form>
			</div>

			<div class="w-full md:w-5/12 lg:w-3/12 md:pl-10 lg:pl-20 pt-20 md:pt-0" :class="{ hidden: !cartShown }">
				<div class="sticky top-67">
					<div class="flex flex-wrap p-10 border border-b-0 border-red bg-white">
						<h4 class="sub-heading text-red w-full">Cart Summary</h4>
					</div>
					<div class="flex flex-wrap p-10 border border-red border-b-0 bg-white" v-for="(room, index) in cart.items"
						:key="index">
						<div class="flex flex-wrap pb-20">
							<p class="body-copy text-red">{{ room.category_name }}</p>
						</div>
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Arrival</p>
							<p class="body-copy text-red w-1/2">{{ room.period_from | formatDate }}</p>
						</div>
						<div class="flex justify-between w-full pb-20">
							<p class="body-copy text-red">Departure</p>
							<p class="body-copy text-red w-1/2">{{ room.period_to | formatDate }}</p>
						</div>
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Tariff</p>
							<p class="body-copy text-red w-1/2">{{ room.tariff.tariff_label }}</p>
						</div>
						<div class="flex justify-between w-full pb-20">
							<p class="body-copy text-red">Guests</p>
							<p class="body-copy text-red w-1/2">{{ room.adults + room.children + room.infants }}</p>
						</div>
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Adults</p>
							<p class="body-copy text-red w-1/2">{{ room.adults }}</p>
						</div>
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Children</p>
							<p class="body-copy text-red w-1/2">{{ room.children }}</p>
						</div>
						<div class="flex justify-between w-full pb-20">
							<p class="body-copy text-red">Infants</p>
							<p class="body-copy text-red w-1/2">{{ room.infants }}</p>
						</div>
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Total</p>
							<p class="body-copy text-red w-1/2">{{ room.tariff.tariff_total | formatMoney }}</p>
						</div>
					</div>
					<div class="flex flex-wrap p-10 border border-red border-b-0 bg-white" v-if="cart.discount">
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Discount ({{ cart.discount.discount_amount }}%)</p>
							<p class="body-copy text-red w-1/2">-{{ discount | formatMoney }}</p>
						</div>
					</div>
					<div class="flex flex-wrap p-10 border border-red border-b-0 bg-white">
						<div class="flex justify-between w-full">
							<p class="body-copy text-red">Transaction Fee</p>
							<p class="body-copy text-red w-1/2">{{ fee | formatMoney }}</p>
						</div>
					</div>
					<div class="flex flex-wrap p-10 border border-b-0 border-red bg-white" v-if="cart.addons.length > 0">
						<p class="body-copy text-red">Add-ons</p>
					</div>
					<div class="flex flex-wrap p-10 border border-red border-t-0 bg-white" v-if="cart.addons.length > 0">
						<div class="flex justify-between w-full" v-for="(addon, index) in cart.addons" :key="index">
							<p class="body-copy text-red w-5/12">{{ addon.qty }} x {{ addon.name }}</p>
							<p class="body-copy text-red w-1/2">{{ addon.amount * addon.qty | formatMoney }}</p>
						</div>
					</div>
					<div class="flex flex-wrap p-10 border border-red border-t-0 bg-red">
						<div class="flex justify-between w-full">
							<p class="body-copy text-off-white">Total</p>
							<p class="body-copy text-off-white w-1/2">{{ (totalPrice + fee) - discount | formatMoney }}</p>
						</div>
					</div>

					<div class="flex flex-wrap mt-30 p-10 border border-red justify-between bg-white"
						v-if="paymentSection && tariffLabel == 'Flexible Rate'">
						<div class="w-6/12 pr-10 lg:pr-20">
							<input type="text" class="form-field border-b mb-0" :class="{ 'opacity-50': discountDisabled }"
								:placeholder="discountPlaceholder" v-model="cart.discount_code" value="" :disabled="discountDisabled">
						</div>
						<div class="w-6/12">
							<button type="button" class="btn btn-red w-full py-7" :class="{ 'opacity-50': discountDisabled }"
								@click="searchDiscount()" :disabled="discountDisabled">Redeem</button>
						</div>
					</div>

					<div class="flex flex-wrap mt-30" v-if="!paymentSection">
						<button class="btn btn-red w-full"
							@click="paymentSection = true; addons = false; bookingDetails = false;">Proceed To Check Out</button>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-row text-center h-screen" :class="{ hidden: !success }">
			<div class="w-2/12 m-auto pb-30">
				<h2 class="secondary-heading text-red pb-60">Booking Confirmation</h2>

				<p class="body-copy text-red markdown">Your booking details will arrive in your inbox shortly.</p>
				<a href="/" class="btn-link text-red border-red lowercase text-14 tracking-1">return home</a>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Swiper from 'swiper/dist/js/swiper.js';
import Common from './mixins/common';
import moment from 'moment';
import Choices from "choices.js";
import VueMask from 'v-mask';
import creditCardType from 'credit-card-type';
import axios from 'axios';

Vue.use(VueMask);

export default {
	name: 'booking-checkout',
	props: [],

	mixins: [Common],

	data() {
		return {
			checkoutShown: true,
			cartShown: true,
			bookingDetails: true,
			addons: false,
			paymentSection: false,
			success: false,
			loading: false,
			newsletter: false,

			lightQuantity: 0,

			discountPlaceholder: 'Discount Code',
			discountDisabled: false,

			guests: [
				{
					'guest_firstname': 'NewBook',
					'guest_lastname': 'Support',
					'address_street': 'Level 2, 9 Ouyan Street',
					'address_city': 'Surfers Paradise',
					'address_postcode': '4217',
					'address_state_name': '',
					'address_country_name': '',
					'guest_email': 'support@newbook.cloud',
					'guest_phone': '0400000000'
				}
			],

			'adults': 1,
			'children': 0,
			'period_from': '2016-10-25',
			'period_to': '2016-10-28',

			guest: {
				email: '',
				first_name: '',
				last_name: '',
				address: '',
				city: '',
				state: '',
				primary_phone: '',
				secondary_phone: '',
				post_code: '',
				country: ''
			},

			cart: this.$store.getters.cart,
			inventories: [],
			reasons: [
				{ id: 4, name: 'Leisure' },
				{ id: 2, name: 'Business' },
				{ id: 6, name: 'Groups' },
				{ id: 5, name: 'Crew' }
			],

			booking: {},
			transaction: {},
			payment: {},
			cardToken: {},

			errors: []

		}
	},

	mounted() {
		var swiper = new Swiper('.swiper-room-gallery-container', {
			loop: true,
			spaceBetween: 0,
			centeredSlides: false,
			slidesPerView: 1,
			autoHeight: true,

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
		const vueSelects = document.querySelectorAll("select.vue-select");
		vueSelects.forEach(vueSelect => {
			const choices = new Choices(vueSelect, {
				itemSelectText: '',
				searchEnabled: false,
				classNames: {
					containerOuter: 'choices bordered'
				},

			});
		})


		this.getAddons();
		// this.getReasonsList();
	},

	computed: {
		totalPrice() {
			let totalItems = _.sumBy(this.cart.items, function (item) {
				return parseFloat(item.tariff.tariff_total)
			});

			let totalAddons = _.sumBy(this.cart.addons, function (addon) {
				return parseFloat(addon.amount * addon.qty)
			});

			return totalItems + totalAddons;
		},
		chunkedInventoryList() {
			let list = [];

			this.inventories.forEach(element => {
				let item = {
					id: element.id,
					name: element.name,
					amount: element.amount,
					additional_information: element.additional_information,
					images: element.images,
					qty: 0
				};
				list.push(item);
			});

			return _.chunk(list, 2);
		},
		tariffLabel() {
			return this.cart.items[0].tariff.tariff_label;
		},
		fee() {
			return this.totalPrice * (this.cart.fee / 100);
		},
		discount() {
			let discount = 0;

			if (this.cart.discount) {
				discount = this.totalPrice * (this.cart.discount.discount_amount / 100);
			}

			return discount;
		}
	},

	watch: {
		'cart.card_number': function (value) {
			if (value == '') {
				this.cart.card_type = '';

				return;
			}

			let cardType = creditCardType(value);
			this.cart.card_type = cardType[0].type;
		}
	},

	methods: {
		showSearch() {
			this.$emit('show-component', 'BookingSearch');
		},

		showSuccess() {
			this.checkoutShown = false;
			this.success = true;

			// window.location.href = '/success';
		},

		addGuest() {
			this.guests.push({
				'guest_firstname': '',
				'guest_lastname': '',
				'address_street': '',
				'address_city': '',
				'address_postcode': '',
				'address_state_name': '',
				'address_country_name': '',
				'guest_email': '',
				'guest_phone': ''
			});
		},

		searchDiscount() {
			this.$axios.post('/actions/newbook/search/search-discount', JSON.stringify({ name: this.cart.discount_code }))
				.then(res => {

					let discountData = null;
					if (res.data.data && Object.values(res.data.data).length) {
						discountData = Object.values(res.data.data)[0];
						if (discountData.name) {
							this.cart.discount = discountData;
							this.discountDisabled = true;
							this.discountPlaceholder = 'Discount Code';
						}
					}
					if (!discountData) {
						this.cart.discount_code = '';
						this.cart.discount = null;
						this.discountPlaceholder = 'Invalid Code';
						this.discountDisabled = false;
					}
				});
		},

		getNumberOfNigths(start_date, end_date) {
			let start = moment(start_date);
			let end = moment(end_date);

			return end.diff(start, 'days');
		},

		getAddons() {
			// let room = this.cart.items[0];
			// let nigths = this.getNumberOfNigths(room.period_from, room.period_to);

			this.$axios.get('/actions/newbook/search/get-inventory-list')
				.then(res => {
					// res.data.data.forEach(addon => {
					//     addon.amount = parseFloat(addon.amount) * nigths;
					// });
					this.inventories = res.data.data.filter(item => item.name.search('Take Away') == -1);
				});
		},

		addInventory(addon) {
			let index = _.findIndex(this.cart.addons, { id: addon.id });
			if (index !== -1) {
				addon.qty += 1;
				this.cart.addons.splice(index, 1, addon);
			} else {
				addon.qty = 1;
				this.cart.addons.push(addon);
			}

			this.$store.commit('addInventoryListToCart', this.cart.addons);
		},

		decreaseInventory(addon) {
			let index = _.findIndex(this.cart.addons, { id: addon.id });
			if (index !== -1) {
				if (addon.qty > 0) {
					addon.qty -= 1;
					this.cart.addons.splice(index, 1, addon);
				}

				if (addon.qty == 0) {
					this.cart.addons.splice(index, 1);
				}
			}

			this.$store.commit('addInventoryListToCart', this.cart.addons);
		},

		getReasonsList() {
			this.$axios.get('/actions/newbook/search/get-reasons-list')
				.then((res) => {
					this.reasons = res.data.data;
					const choices = new Choices('select.vue-select', {
						itemSelectText: '',
						searchEnabled: false,
						classNames: {
							containerOuter: 'choices bordered'
						}
					});
				});
		},

		async authorizeCard() {
			let result = await this.$axios.post('/actions/newbook/search/authorize-card', JSON.stringify(this.cart));
			if (!result.data.success) {
				throw new Error('Error authorzing card');
			}

			return result.data;
		},

		async createCardToken() {
			let result = await this.$axios.post('/actions/newbook/search/create-card-token', JSON.stringify(this.cart));
			if (!result.data.success) {
				throw new Error(result.data.message);
			}

			return result.data;
		},

		async authorizeCardFlexibe() {
			let result = await this.$axios.post('/actions/newbook/search/authorize-card-flexibe', JSON.stringify(this.cart));
			if (!result.data.success) {
				throw new Error(result.data.message);
			}

			return result.data;
		},

		async capturePayment(transactionID, transactionAmount) {
			let data = {
				transactionID: transactionID,
				transactionAmount: transactionAmount
			};

			let result = await this.$axios.post('/actions/newbook/search/proccess-capture', JSON.stringify(data));

			if (!result.data.success) {
				throw new Error('Error capturing payment');
			}

			return result.data;
		},

		async flexibleRate() {
			this.errors = [];
			let form = document.querySelector('#guestForm');

			if (form.checkValidity()) {
				this.loading = true;
				try {
					if (this.newsletter) {
						this.subscribeToNewsletter();
					}
					let result, authorizeCard;

					result = await this.authorizeCardFlexibe();
					authorizeCard = result.data;

					console.log('authorize card');
					console.log(authorizeCard);

					this.transaction = authorizeCard.responseData;

					let transactionID = authorizeCard.transactionID;
					let transactionAmount = authorizeCard.responseData.transactionAmount;

					let booking = await this.proccessBooking();
					this.booking = booking.data;
					console.log('booking');
					console.log(booking);

					// feeCharge = await this.createFeeCharge();
					// console.log('feeCharge');
					// console.log(feeCharge);

					let cardToken = await this.createCardToken();
					this.cardToken = cardToken.data.responseData;

					console.log('cardToken');
					console.log(cardToken);

					result = await this.postCardToken();
					console.log(result);

					// let emailConfirmation = await this.sendConfirmationEmail('flexibe', this.booking.booking_id);
					// console.log('emailConfirmation');
					// console.log(emailConfirmation);

					this.loading = false;
					this.showSuccess();
					this.scrollTop();
				} catch (error) {
					console.log('error');
					console.error(error);

					if (error.response && error.response.data.error) {
						this.errors.push(error.response.data.error);
					} else {
						this.errors.push(error.message);
					}
					this.loading = false;
					this.scrollTop();
				}
			} else {
				form.reportValidity();
			}
		},

		async standardRate() {
			this.errors = [];
			let form = document.querySelector('#guestForm');

			if (form.checkValidity()) {
				this.loading = true;
				try {
					if (this.newsletter) {
						this.subscribeToNewsletter();
					}
					let result, authorizeCard;

					result = await this.authorizeCard();
					authorizeCard = result.data;

					console.log('authorize card');
					console.log(authorizeCard);

					this.transaction = authorizeCard.responseData;

					let transactionID = authorizeCard.transactionID;
					let transactionAmount = authorizeCard.responseData.transactionAmount;

					let booking = await this.proccessBooking();
					this.booking = booking.data;

					console.log('booking');
					console.log(booking);

					let proccessPayment = await this.capturePayment(transactionID, transactionAmount);
					this.transaction = proccessPayment.data.responseData;
					console.log('proccessPayment');
					console.log(proccessPayment);

					let bookingPayment, feePayment, feeCharge;

					bookingPayment = await this.createBookingPayment();
					console.log('bookingPayment');
					console.log(bookingPayment);

					// feeCharge = await this.createFeeCharge();
					// console.log('feeCharge');
					// console.log(feeCharge);

					// feePayment = await this.createFeePayment();
					// console.log('feePayment');
					// console.log(feePayment);

					let cardToken = await this.createCardToken();
					this.cardToken = cardToken.data.responseData;

					console.log('cardToken');
					console.log(cardToken);

					result = await this.postCardToken();
					console.log('postCardToken');
					console.log(result);

					// let emailConfirmation = await this.sendConfirmationEmail('flexibe', this.booking.booking_id);
					// console.log('emailConfirmation');
					// console.log(emailConfirmation);

					this.loading = false;
					this.showSuccess();
					this.scrollTop();
				} catch (error) {
					console.log('error');
					console.log(error);
					if (error.response && error.response.data.error) {
						this.errors.push(error.response.data.error);
					} else {
						this.errors.push(error.message);
					}
					this.loading = false;
					this.scrollTop();
				}
			} else {
				form.reportValidity();
			}
		},

		async placeBooking() {
			this.flexibleRate();
			// if (this.tariffLabel == 'Flexible Rate' || this.tariffLabel == 'Hot Deal') {
			//     this.flexibleRate();
			// } else {
			//     this.standardRate();
			// }
		},

		subscribeToNewsletter() {
			const form = document.querySelector('#newsletter-form');

			let formData = new FormData(form);
			formData.set('email', this.cart.items[0].guest.guest_email);
			formData.delete('redirect');

			// for(var pair of formData.entries()) {
			//     console.log(pair[0]+ ', '+ pair[1]); 
			// }

			axios.post('/', formData)
				.then(response => console.log(response));
		},

		async sendConfirmationEmail(type, booking_id) {
			let data = {
				type: this.tariffLabel,
				booking_id: booking_id
			};
			let result = await this.$axios.post('/actions/newbook/search/send-confirmation-email', JSON.stringify(data));
			let email = result.data;

			return email;
		},

		async proccessBooking() {
			let result = await this.$axios.post('/actions/newbook/search/place-booking', JSON.stringify(this.cart));
			let booking = result.data;

			if (!booking.success) {
				this.booking = booking;
				throw new Error(booking.message);
			}

			return booking;
		},

		async proccessPayment() {
			this.loading = true;

			try {
				let result = await this.$axios.post('/actions/newbook/search/proccess-payment', JSON.stringify(this.cart));
				let transaction = result.data;

				if (transaction.success) {
					this.transaction = transaction;
				} else {
					this.errors.push(transaction.message);
				}

				this.loading = false;

				return transaction;
			} catch (error) {


				if (error.response && error.response.data.error) {
					this.errors.push(error.response.data.error);
				} else {
					this.errors.push(error.response.data);
				}
				this.loading = false;
				this.scrollTop();

				return transaction;
			}
		},

		async createBookingPayment(transactionAmount) {
			if (typeof transactionAmount === 'undefined') {
				transactionAmount = this.totalPrice + this.fee;
			}

			let params = {
				'account_id': this.booking.account_id,
				'booking_id': this.booking.booking_id,
				'gl_category_id': 1,
				'transactionAmount': transactionAmount,
				'cardType': this.transaction.cardType,
				'transactionID': this.transaction.transactionID,
				'card_name': this.cart.card_name,
				'card_number': this.cart.card_number
			};

			console.log('createBookingPayment');
			console.log(params);

			let result = await this.$axios.post('/actions/newbook/search/create-booking-payment', JSON.stringify(params));
			let payment = result.data;

			if (!payment.success) {
				throw new Error(payment.message);
			}

			return payment;
		},

		async createFeeCharge() {
			let transactionAmount = this.cart.fee;

			let params = {
				'account_id': this.booking.account_id,
				'booking_id': this.booking.booking_id,
				'gl_category_id': 28,
				'gl_account_code': '00-0',
				'transactionAmount': this.fee,
				'cardType': this.transaction.cardType
			};

			console.log('createFeeCharge');
			console.log(params);

			let result = await this.$axios.post('/actions/newbook/search/create-fee-charge', JSON.stringify(params));
			let charge = result.data;

			if (!charge.success) {
				throw new Error(charge.message);
			}

			return charge;
		},

		async createFeePayment() {
			let transactionAmount = this.fee;

			let params = {
				'account_id': this.booking.account_id,
				'booking_id': this.booking.booking_id,
				'gl_category_id': 28,
				'transactionAmount': transactionAmount,
				'cardType': this.transaction.cardType,
				'transactionID': this.transaction.transactionID,
				'card_name': this.cart.card_name,
				'card_number': this.cart.card_number
			};

			console.log('createFeePayment');
			console.log(params);

			let result = await this.$axios.post('/actions/newbook/search/create-fee-payment', JSON.stringify(params));
			let payment = result.data;

			if (!payment.success) {
				throw new Error(payment.message);
			}

			return payment;
		},

		async postCardToken() {
			let params = {
				'for': 'guests',
				'for_id': this.booking.guest_id,
				'token': this.cardToken.cardID,
				'last_digits': this.cart.card_number.slice(-4),
				'card_name': this.cart.card_name,
				'card_type': this.cart.card_type,
				'expiry': this.cart.card_expiry
			};

			console.log('postCardToken');
			console.log(params);

			let result, cardToken;

			try {
				result = await this.$axios.post('/actions/newbook/search/post-card-token', JSON.stringify(params));
				cardToken = result.data;
				console.log('result');
				console.log(result);
			} catch (err) {
				console.log('postCardToken error');
				console.log(err.response);

				if (err.response.status != 412) {
					throw new err;
				}
			}

			if (cardToken && !cardToken.success) {
				throw new Error(cardToken.message);
			}

			return cardToken;
		},

		toggleText(event) {
			let element = event.target.parentNode.parentNode.querySelector('span.content');
			let button = event.target;

			if (element.style.maxHeight) {
				element.style.maxHeight = null;
				button.innerHTML = 'More Details';
			} else {
				element.style.maxHeight = element.scrollHeight + "px";
				button.innerHTML = 'Less Details';
			}
		},

		removeRoom(index) {
			this.cart.items.splice(index, 1);

			if (this.cart.items.length == 0) {
				this.showSearch();
			}
		},

		getCartTotal() {
			let itemsTotal = _.sumBy(this.cart.items, 'tariff.tariff_total');
		}
	}
}
</script>